.filter-dropdown .MuiOutlinedInput-root {
    width: 300px;
    border-radius: 8px;
}

.filter-placeholder {
    pointer-events: none;
    position: absolute;
    color: black;
    left: 14px;
    top: 8px;
}

.filter-label {
    color: #51525C;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 8px;
}

.filter-dropdown .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: black;
    box-shadow: none;
}

.custom-placeholder {
    pointer-events: none;
    position: absolute;
    left: 10px;
    top: 8px;
    z-index: 10;
    color: rgba(0, 0, 0, 0.25);
}

.MuiPaper-root.custom-menu {
    top: 410px !important;
    border-radius: 4px;
}

.filter-menu-paper.MuiPaper-root {
    max-height: 300px;
}

.custom-menu-filter.MuiPaper-root {
    top: 513px !important;
    border-radius: 4px;
}

.custom-menu .MuiMenuItem-root.Mui-selected,
.custom-menu-filter .MuiMenuItem-root.Mui-selected {
    background-color: #56575c;
    color: white;
    display: flex;
    justify-content: space-between;
    padding-right: 30px;
    margin-left: 5px;
    margin-right: 5px;
}

.custom-menu .MuiMenuItem-root.Mui-selected::before,
.custom-menu-filter .MuiMenuItem-root.Mui-selected::before {
    content: '✓';
    color: white;
    margin-left: 8px;
    position: absolute;
    right: 10px
}

.selected-count {
    background-color: #f0f0f0;
    padding: 1px 6px;
    border-radius: 4px;
    display: inline-block;
    color: rgba(0, 0, 0, 0.50);
}

.custom-menu .MuiMenuItem-root.Mui-selected:hover,
.custom-menu-filter .MuiMenuItem-root.Mui-selected:hover {
    background-color: #56575c
}

.all-option-selected.MuiMenuItem-root.Mui-selected {
    background-color: transparent;
    color: inherit;
}

.all-option-selected.MuiMenuItem-root.Mui-selected:hover {
    background-color: white;
}

.custom-divider.MuiDivider-root {
    margin-top: 45px;
    margin-left: 80px;
}