.customTab .MuiTabs-indicator {
  background-color: black !important;
}

.customTab .Mui-selected {
  color: black !important;
  font-size: 16px !important;
}
.customTab .MuiTab-root {
  text-transform: unset !important;
  font-size: 16px !important;
}

.customTabs .MuiTabs-indicator {
  display: none;
}

#tabs button{
  min-width: 0;
  width: auto;
  padding: 8px;
  margin-right: 20px;
}