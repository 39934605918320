  .MuiDrawer-root .nav-drawer-header{
    display: flex;
    flex-direction:row ;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: none;
}

  .nav-open.MuiListItemButton-root{
    display: flex;
    justify-content: center;
    border-radius: 3px;
  }

  .nav-open.MuiListItemButton-root:hover{
    background-color: #808080;
  }

  .background-color-active.MuiListItemButton-root {
    background-color: #808080;
    color: white;
  }

  .background-color-inactive.MuiListItemButton-root{
    background-color: inherit;
    color:inherit;
  }

  .nav-icon.MuiListItemIcon-root{
    min-width: 0;
  }

  .nav-icon-margin-set.MuiListItemIcon-root{
    margin-right: 30px;
  }

  .nav-icon-margin-unset.MuiListItemIcon-root{
    margin-right: auto;
  }

  .logout-nav-button.MuiListItemButton-root{
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 3px;
    color: white;
    min-height: 48px;
  }

  .logout-nav-button.MuiListItemButton-root:hover{
    background-color: #BC1B06;
  }

  .logout-button-centre.MuiListItemButton-root{
    display: flex;
    justify-content: center;
  }

  .logout-button-initial.MuiListItemButton-root{
    display: flex;
    justify-content: initial;
  }

  .logout-icon.MuiListItemIcon-root{
    display: flex;
    justify-content: center;
     min-width: 0;
  }

  .logout-icon-margin-set.MuiListItemIcon-root{
    margin-right: 30px;
  }

  .logout-icon-margin-unset.MuiListItemIcon-root{
    margin-right: auto;
  }

  .nav-list-item.MuiListItem-root{
    display: block;
  }