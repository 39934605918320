.wallet-details .MuiOutlinedInput-input {
  width: 450px;
}

.wallet-details .select-class {
  border-radius: 22px;
}

.edit-balance .MuiOutlinedInput-small {
  border-radius: 24px;
}

.text-field .MuiOutlinedInput-root {
  border-radius: 22px;
  width: 320px;
  background-color: rgba(244, 244, 245);
  border-color: rgb(228 228 231);
}

.send-Notification .select-classes,
.transfer-balance .select-classes {
  border-radius: 24px;
}

.text-field .MuiOutlinedInput-root ::placeholder,
.select-classes .MuiSelect-outlined ::placeholder {
  color: rgb(82 82 91);
}

.fixed-value {
  width: 320px;
  border-radius: 24px;
  height: 40px;
  border-color: rgb(244, 244, 245);
}

.text-field .MuiOutlinedInput-notchedOutline,
.textarea-field .MuiOutlinedInput-notchedOutline,
.edit-balance .MuiOutlinedInput-notchedOutline,
.select-classes .MuiOutlinedInput-notchedOutline ,
.add-configuration .filter-select .MuiOutlinedInput-notchedOutline{
  border: none;
}

.dashboard-scroll {
  overflow-y: auto;
  height: 100%;
}
 
.dashboard .multi-select {
  border-radius: 8px;
  width: 180px;
  height: 36px;
}

.custom-paper{
  margin-top: 12px;
  border-radius: 12px;
}

.dashboard .MuiFormControl-root{
  margin-left: 18px;
}

.dashboard .MuiDivider-root{
  height: 38px;
  background-color: rgb(209,209,214);
}

.add-configuration .scroll-hidden {
  scrollbar-width: none;
}

.add-configuration .scroll-hidden::-webkit-scrollbar {
  display: none; 
}

.sweep-configuration-filter .filter-select{
  border-radius: 8px;
  width:249px;
}

.add-configuration .filter-select{
  border-radius: 24px;
  width: 320px;
  background-color: rgb(244 244 245);
}

.custom-paper ul{
  max-Height: 400px;
  overflow-Y: 'auto';
  padding: 0px
}

.crypto-settings-text-field{
  width: 80px;
}

.custom-password-field {
  background-color: rgb(233, 241, 248);
  border-radius: 8px;
}

.MuiTabs-root .MuiTab-root.custom-tab.Mui-selected {
  color: black;
}

.MuiTabs-root .MuiTab-root.custom-tab {
  text-transform: none;
  font-size: 16px;
  padding: 0;
  margin-right: 10px;
}