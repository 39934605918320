#common-datepicker-container>div>input::placeholder,
#common-datepicker-container>div>button,
#demo-multiple-checkbox-label {
    color: rgba(0, 0, 0, 0.4);
    bottom: 31px;
    right: 20px;
}

#common-datepicker-container>div>button {
    position: absolute;
    right: 0px;
    top: 0px;
}

#common-datepicker-container>div {
    width: 100%;
}

#common-datepicker-container {
    position: relative;
}

#common-datepicker-container:hover {
    border-color: black;
}

#common-datepicker-container>div>input {
    height: 38px;
    width: 300px;
}

.datepicker-dropdown div {
    bottom: 35px;
    right: 2px;
}