#reset {
  border-radius: 8px;
  border: 2px solid black;
  color: black;
  text-transform: none;
}
#search {
  border-radius: 8px;
  color: white;
  background-color: black;
  text-transform: none;
}
